import React from "react";
import { PageWrapper } from "~components/Core";
import FooterFour from '~sections/app/FooterFour'

const header = {
  headerClasses: "site-header site-header--menu-start light-header",
  containerFluid:false,
}

export default function errorPage() {
  return (
    <PageWrapper innerPage={true}>
        <div>
          <p>404!</p>
        </div>
        <FooterFour/>
    </PageWrapper>
  )
}